import React, { useState } from 'react';
import MicRecorder from 'mic-recorder-to-mp3';
import { ClipLoader } from 'react-spinners'; // Import the spinner
import axios from 'axios';
import "./Transcription.css"

const Mp3Recorder = new MicRecorder({ bitRate: 128 });

function Transcription() {
  const [isRecording, setIsRecording] = useState(false);
  const [blobURL, setBlobURL] = useState('');
  const [isBlocked, setIsBlocked] = useState(false);
  const [transcription, setTranscription] = useState(''); // New state for the transcription
  const [isEditing, setIsEditing] = useState(false);
  const [editedTranscription, setEditedTranscription] = useState('');
  const [gptTranscription, setGptTranscription] = useState(''); // New state for the GPT-3.5 transcription
  const [selectedTranscription, setSelectedTranscription] = useState(''); // New state for the selected transcription
  const [isLoadingTranscription, setIsLoadingTranscription] = useState(false); // New state for the transcription loading
  const [isLoadingGpt, setIsLoadingGpt] = useState(false); // New state for the GPT-3.5 loading



  navigator.mediaDevices.getUserMedia({ audio: true },
    () => {
      console.log('Permission Granted');
      setIsBlocked(false);
    },
    () => {
      console.log('Permission Denied');
      setIsBlocked(true);
    },
  );

  const confirmEdit = () => {
    setSelectedTranscription(editedTranscription);
    setIsEditing(false);
  };

  const startEditing = () => {
    setEditedTranscription(selectedTranscription);
    setIsEditing(true);
  };

  // Add this function to handle the copy to clipboard button click
  const copyToClipboard = () => {
    navigator.clipboard.writeText(transcription);
  };

  const start = () => {
    // Reset the states for transcription, edited transcription, GPT transcription, and selected transcription
    setTranscription('');
    setEditedTranscription('');
    setGptTranscription('');
    setSelectedTranscription('');
    if (isBlocked) {
      console.log('Permission Denied');
    } else {
      Mp3Recorder
        .start()
        .then(() => {
          setIsRecording(true);
        }).catch((e) => console.error(e));
    }
  };

  const stop = () => {
    Mp3Recorder
      .stop()
      .getMp3()
      .then(async ([buffer, blob]) => {
        const blobURL = URL.createObjectURL(blob)
        setBlobURL(blobURL);
        setIsRecording(false);
        setIsLoadingTranscription(true);

        // Send the audio data to the Whisper API
        const formData = new FormData();
        formData.append('file', blob);
        formData.append('model', 'whisper-1');

        const response = await axios.post('https://api.openai.com/v1/audio/transcriptions', formData, {
          headers: {
            'Authorization': 'Bearer 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',
            'Content-Type': 'multipart/form-data',
            "language": "pt",
            "prompt": "Transcrição de áudio em português brasileiro para uma clínica de dermatologia. Use termos médicos e técnicos.",
          }
        });

        // Set the transcription
        setIsLoadingTranscription(false);
        setTranscription(response.data.text);

        setIsLoadingGpt(true);
        // Send the transcription to the GPT-3.5 model
        const gptResponse = await axios.post('https://api.openai.com/v1/chat/completions', {
          'model': 'gpt-3.5-turbo',
          'messages': [
            {
              'role': 'system',
              'content': 'Me responda em primeiro pessoa, como se você escrevesse o texto. Não adicione muito texto, seja sucinto. Esse é uma transcrição de uma médica dermatológica, sobre uma consulta. Corrija os erros ortográficos e de concordância, e use termos médicos e técnicos. Não adicione formatação'
            },
            {
              'role': 'user',
              'content': response.data.text
            }
          ]
        }, {
          headers: {
            'Authorization': 'Bearer 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',
            'Content-Type': 'application/json'
          }
        });

        // Set the GPT-3.5 model's response
        setIsLoadingGpt(false);
        setGptTranscription(gptResponse.data.choices[0].message.content);

      }).catch((e) => console.log(e));
  };

  const selectTranscription = (type) => {
    if (type === 'original') {
      setSelectedTranscription(transcription);
      setGptTranscription('');
      setTranscription('');
    } else if (type === 'gpt') {
      setSelectedTranscription(gptTranscription);
      setTranscription('');
      setGptTranscription('');
    }
  };

  return (
    <div>
      <header className="audio-controls">
        {!isRecording && <button className="button" onClick={start}>Gravar áudio</button>}
        {isRecording && <button className="button" onClick={stop}>Parar gravação</button>}
        {isRecording && <p>Gravando...</p>}
        <audio src={blobURL} controls />
        {/* {blobURL && <a href={blobURL} download="recording.mp3" className="button">Baixar</a>}  */}
      </header>
      <div className="transcription-area">
        {isEditing ? (
            <div className="textarea-wrapper">
                <textarea className="textarea-edit" value={editedTranscription} onChange={(e) => setEditedTranscription(e.target.value)} />
                <button className="button" onClick={confirmEdit}>Confirmar Edição</button>
            </div>
        ) : (
          <>
            {isLoadingTranscription && <div><ClipLoader color="#FFFFFF" loading={isLoadingTranscription} size={50} /><p>Carregando transcrição...</p></div>}

            {transcription && <p>{transcription}</p>}
            {!isLoadingTranscription && isLoadingGpt && <div><ClipLoader color="#FFFFFF" loading={isLoadingGpt} size={50} /><p>Tentando melhorar o texto com ChatGPT...</p></div>}
            
            {transcription && <button className="button" onClick={() => selectTranscription('original')}>Selecionar transcrição original</button>}
            {gptTranscription && <p>{gptTranscription}</p>}
            {gptTranscription && <button className="button" onClick={() => selectTranscription('gpt')}>Selecionar transcrição com ChatGPT</button>}
            {selectedTranscription && <p>{selectedTranscription}</p>}
            {selectedTranscription && <button className="button" onClick={startEditing}>Editar texto</button>}
            {selectedTranscription && <button className="button" onClick={copyToClipboard}>Copiar texto</button>}
          </>
        )}
      </div>
    </div>
  );
}

export default Transcription;