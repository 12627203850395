import React, { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Resources, Buildings, Professionals, Procedimentos, Pacientes, fetchPatients } from './mockDatabase';
import axios from 'axios'; // Import axios at the top of your file
import { ClipLoader } from 'react-spinners'; // Import the spinner
import PatientSearchSelect from './PacientSearchSelect';

  function AddEventModal({ onClose, onEventAdded, resources, events, eventToEdit }) {
    const now = new Date();
    const currentDate = now.toISOString().slice(0,10);
    const currentTime = now.toISOString().slice(11,16);

    const [procedureDuration, setProcedureDuration] = useState('15');
    const [startTime, setStartTime] = useState(eventToEdit ? eventToEdit.start : `${currentDate}T${currentTime}`);
    const [endTime, setEndTime] = useState(eventToEdit ? eventToEdit.end : `${currentDate}T${currentTime}`);
    const [selectedBuildingId, setSelectedBuildingId] = useState(eventToEdit ? eventToEdit.buildingId : '');
    const [selectedProfessionalId, setSelectedProfessionalId] = useState(eventToEdit ? eventToEdit.professionalId : '');
    const [selectedProcedimentoId, setSelectedProcedimentoId] = useState(eventToEdit ? eventToEdit.procedimentoId : '');
    const [selectedPacienteId, setSelectedPacienteId] = useState(eventToEdit ? eventToEdit.pacienteId : '');
    const [isOpenTextArea, setIsOpenTextArea] = useState(false);
    const [textAreaValue, setTextAreaValue] = useState('');
    const [isWhatsAppMessageLoading, setIsWhatsAppMessageLoading] = useState(false);
    const [patients, setPatients] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');

useEffect(() => {
    const handleEscape = (e) => {
        if (e.key === 'Escape') onClose();
    };
    window.addEventListener('keyup', handleEscape);
    return () => {
        window.removeEventListener('keyup', handleEscape);
    };
    }, [onClose]);

    const handleBackgroundClick = (e) => {
    if (e.target === e.currentTarget) onClose();
    };

    const handleOpenTextArea = () => {
        setIsOpenTextArea(true);
    };

    const handlePatientSearch = async (searchTerm) => {
        const patients = await fetchPatients(searchTerm);
        setPatients(patients);
        console.log(patients);
      };

    const sendMessageWithGPT3 = async () => {
        // const selectedPaciente = Pacientes.find(p => p.id === selectedPacienteId);
        const selectedProfessional = Professionals.find(p => p.id === selectedProfessionalId);
        const selectedProcedimento = Procedimentos.find(p => p.id === selectedProcedimentoId); 


        setIsWhatsAppMessageLoading(true); // Set the loading state to true before the API call
        let gptResponse;
        try {
            gptResponse =  await axios.post('https://api.openai.com/v1/chat/completions', {
                'model': 'gpt-3.5-turbo',
                'messages': [
                    {
                        'role': 'system',
                        'content': 'Escreva uma mensagem de confirmação e agrecimento de Whatsapp para o paciente, assuma que você já estava falando com ele, então não precisa de saudação, e não é a médica falando, mas sim outra pessoa. O nome da clínica é "Clínica Patricia Bandeira de Melo".Use esses dados:'
                    },
                    {
                        'role': 'user',
                        'content': `Paciente: ${selectedPacienteId.name}\nMedico: ${selectedProfessional.name}\nProcedimento: ${selectedProcedimento.name}\nHorário: ${new Date(startTime).toLocaleDateString()} ${new Date(startTime).toLocaleTimeString()}`
                    }
                ]
            }, {
                headers: {
                    'Authorization': 'Bearer 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',
                    'Content-Type': 'application/json'
                }
            });
            console.log('gptResponse:', gptResponse);
            setTextAreaValue(gptResponse.data.choices[0].message.content);
        } catch (error) {
            console.error('Error with GPT-3.5 model:', error);
        } finally {
            setIsOpenTextArea(true);
            setIsWhatsAppMessageLoading(false); // Set the loading state to false after the API call
        }
    };

    const handleAddEvent = async() => {
        const selectedBuilding = Buildings.find(b => b.id === selectedBuildingId);
        const selectedProfessional = Professionals.find(p => p.id === selectedProfessionalId);
        const selectedProcedimento = Procedimentos.find(p => p.id === selectedProcedimentoId);    
        // const selectedPaciente = Pacientes.find(p => p.id === selectedPacienteId);
        
    
        if (selectedBuilding && selectedProfessional && selectedProcedimento) {
          const selectedResource = resources.find(
            res => res.title === selectedProfessional.name && res.building === selectedBuilding.name
          );
    
          if (selectedResource) {
            const isDoubleBooked = events.some(event =>
              (event.resourceId === selectedResource.id || event.building === selectedBuilding.name) &&
              ((event.start >= startTime && event.start < endTime) ||
                (event.end > startTime && event.end <= endTime))
            );

            const duration = parseInt(procedureDuration, 10);
            const endTimeConstant = new Date(new Date(startTime).getTime() + duration * 60000).toISOString();

            if (!isDoubleBooked) {
                const newEvent = {
                  eventId: eventToEdit ? eventToEdit.eventId : uuidv4(),
                  title: `${selectedPacienteId.Nome} - ${selectedProcedimento.name}`,
                  start: startTime,
                  end: endTimeConstant,
                  backgroundColor: 'green',
                  borderColor: 'green',
                  resourceId: selectedResource.id,
                  building: selectedBuilding.name,
                  professional: selectedProfessional.name,
                  procedure: selectedProcedimento.name,
                  patient: selectedPacienteId.Nome,
                  professionalId: selectedProfessional.id,
                  patientId: selectedPacienteId.id,
                  // ... other fields you want to update
                };
                
        
                onEventAdded(newEvent);
                // Call the GPT-3.5 model


                try {
                    await sendMessageWithGPT3(newEvent);
                } catch (error) {
                    console.error('Error with GPT-3.5 model:', error);
                    }
                } else {
                    console.error('Double booking detected');
                }
            }
        }
    }
        

  
      return (
        <div
            onClick={handleBackgroundClick}
            style={{
                position: 'fixed',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: 1000  // This ensures the modal is on top of everything else
            }}
        >
            <div
                style={{
                    backgroundColor: 'grey',
                    padding: '20px',
                    borderRadius: '8px',
                    width: '400px',  // Adjusted width to fit the new modal
                    overflowY: 'auto',  // This makes the div scrollable
                    maxHeight: '400px'  // Set a max height
                }}
            >
                <div style={{ marginBottom: '10px' }}>
                    <label>Sala do procedimento:</label>
                    <select
                        value={selectedBuildingId}
                        onChange={(e) => setSelectedBuildingId(e.target.value)}
                        style={{ width: '100%' }}
                    >
                        <option value="" disabled hidden>Selecione a Sala</option>
                        {Buildings.map(building => (
                            <option value={building.id} key={building.id}>{building.name}</option>
                        ))}
                    </select>
                </div>

                <div style={{ marginBottom: '10px' }}>
                    <PatientSearchSelect onPatientSelect={setSelectedPacienteId} />
                    {/* <label>Paciente:</label>
                    <input
                        value={searchTerm}
                        onChange={e => setSearchTerm(e.target.value)}
                        style={{ width: '100%' }}
                    />
                    <button onClick={() => handlePatientSearch(searchTerm)}>Procurar paciente</button>
                    {patients.length > 0 && (
                        <select
                            value={selectedPacienteId ? selectedPacienteId.pacientId : ''} // Check if selectedPacienteId is defined
                            onChange={e => {
                                const selectedPatient = patients.find(patient => patient.pacientId === e.target.value);
                                setSelectedPacienteId(selectedPatient);
                                console.log(selectedPacienteId);
                            }}
                            style={{ width: '100%' }}
                        >
                            <option value="" disabled hidden>Selecione um paciente</option>
                            {patients.map((patient, index) => (
                                <option value={patient.pacientId} key={index}>
                                    {patient.Nome}
                                </option>
                            ))}
                        </select>
                    )} */}
                </div>

                <div style={{ marginBottom: '10px' }}>
                    <label>Profissional:</label>
                    <select
                        value={selectedProfessionalId}
                        onChange={(e) => setSelectedProfessionalId(e.target.value)}
                        style={{ width: '100%' }}
                    >
                        <option value="" disabled hidden>Selecione o profissional</option>
                        {Professionals.map(prof => (
                            <option value={prof.id} key={prof.id}>{prof.name}</option>
                        ))}
                    </select>
                </div>

                <div style={{ marginBottom: '10px' }}>
                    <label>Procedimento:</label>
                    <select
                        value={selectedProcedimentoId}
                        onChange={(e) => setSelectedProcedimentoId(e.target.value)}
                        style={{ width: '100%' }}
                    >
                        <option value="" disabled hidden>Selelecione o procedimento</option>
                        {Procedimentos.map(proc => (
                            <option value={proc.id} key={proc.id}>{proc.name}</option>
                        ))}
                    </select>
                </div>

                <div style={{ marginBottom: '10px' }}>
                    <label>Horário de início:</label>
                    <input type="datetime-local" value={startTime} onChange={(e) => setStartTime(e.target.value)} step="900" style={{ width: '100%' }} />
                </div>

                <div style={{ marginBottom: '10px' }}>
                    <label>Duration of Procedure (minutes):</label>
                    <select value={procedureDuration} onChange={(e) => setProcedureDuration(e.target.value)} style={{ width: '90%' }}>
                        <option value="15">15</option>
                        <option value="30">30</option>
                        <option value="45">45</option>
                        <option value="60">60</option>
                        <option value="90">90</option>
                        <option value="120">120</option>
                    </select>
                </div>
                <div style={{ marginBottom: '10px' }}>
                        <button onClick={handleAddEvent}>Add Event</button>
                        <button onClick={onClose}>Close</button>
                        {/* {isOpenTextArea && <button onClick={() => navigator.clipboard.writeText(textAreaValue)}>Copy text</button>} */}
                        {/* <button onClick={handleOpenTextArea}>Preencher com IA</button> */}
                </div>
                {isWhatsAppMessageLoading && <ClipLoader color="#FFFFFF" loading={isWhatsAppMessageLoading} size={50} />}

                {isOpenTextArea && (
                    <div style={{ marginTop: '20px' }}>
                        <p>Ideia de mensagem para mandar no WhatsApp:</p>
                        {!isWhatsAppMessageLoading && (
                        <textarea
                            value={textAreaValue}
                            onChange={(e) => setTextAreaValue(e.target.value)}
                            style={{ width: '100%', height: '100px' }}
                        />
                        )}
                        <button onClick={() => navigator.clipboard.writeText(textAreaValue)}>Copiar texto</button>
                    </div>
                )}


            </div>
        </div>
    );
}

export default AddEventModal;
