import React, { useState, useEffect, useMemo  } from 'react';
import './Procedimentos.css';
import Papa from 'papaparse';
import { FaCheck, FaPause } from 'react-icons/fa';  // Import the checkmark icon
import { AiFillDelete } from 'react-icons/ai';  // Import the checkmark icon
import Select from 'react-select';  // Import react-select


function Procedimentos() {
    const [procedimentosData, setProcedimentosData] = useState([]);
    const [procedimento, setProcedimento] = useState('');
    const [procedimentos, setProcedimentos] = useState([{ procedimento: '', subprocedimento: '' }]);
    const [subprocedimento, setSubprocedimento] = useState('');
    const [isSent, setIsSent] = useState(false);  // New state for toggling the text visibility
    const [patientName, setPatientName] = useState('');  // New state for patient name
    const [observacao, setObservacao] = useState('');  // New state for observacao
    const [formError, setFormError] = useState('');  // New state for form errors
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [solicitante, setSolicitante] = useState('');
    const [medico, setMedico] = useState('');
    const [selectedIds, setSelectedIds] = useState([]);
    const [selectedProcedimentoIds, setSelectedProcedimentoIds] = useState([]);
    const [sessionNumbers, setSessionNumbers] = useState([]);


    
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch("https://hnu6z5csar55m3uibywiv27ksa0hsnno.lambda-url.us-east-1.on.aws/"); // Replace with your actual API endpoint
                const jsonData = await response.json(); // Parse the JSON response
            
                // console.log(jsonData)
                // No need to use Papa.parse, as the data is already JSON
                setProcedimentosData(jsonData);
                // console.log(procedimentosData)
            } catch (error) {
                console.error('Error fetching or parsing data:', error);
            }
        };
    
        fetchData();
    }, []);
    
    const sessionOptions = Array.from({ length: 15 }, (_, i) => ({ value: i + 1, label: i + 1 }));


    const handleProcedimentoChange = (option, index) => {
        const newProcedimentos = [...procedimentos];
        newProcedimentos[index].procedimento = option ? option.value : null;
        
        const relatedSubprocedimentos = procedimentosData
            .filter(item => item.Procedimento === option.value);
    
        // If there's only one subprocedimento and it's not "-"
        if (relatedSubprocedimentos.length === 1) {
            newProcedimentos[index].subprocedimento = relatedSubprocedimentos[0].Subprocedimento;
            const id = relatedSubprocedimentos[0].id;
            setSelectedIds([...selectedIds, id]);
        }
        
        
        
        setProcedimentos(newProcedimentos);
    };
    
    
    
    const handleSubprocedimentoChange = (option, index) => {
        const newProcedimentos = [...procedimentos];
        newProcedimentos[index].subprocedimento = option ? option.value : null;
        setProcedimentos(newProcedimentos);
        
        if (option) {
          const foundItems = procedimentosData.filter(item => item.Subprocedimento === option.value && item.Procedimento === newProcedimentos[index].procedimento);
          if (foundItems.length > 0) {
            const id = foundItems[0].id;
            setSelectedIds([...selectedIds, id]);
          } else {
            console.error("Item not found");
          }
        }

      };
    
    

    const handleSolicitanteChange = (option) => {
        setSolicitante(option ? option.value : null);
    };
    
    const handleMedicoChange = (option) => {
        setMedico(option ? option.value : null);
    };
    

    const resetForm = () => {
        setPatientName('');
        setProcedimento(null);  // Set to null
        setSubprocedimento(null);  // Set to null
        setObservacao('');
        setFormError('');
        setIsSent(false);
        setSelectedIds([]);
    };
    
    const procedimentoOptions = useMemo(() => {
        const uniqueProcedimentos = new Set();
        const options = [];
        procedimentosData.forEach(item => {
            if (!uniqueProcedimentos.has(item.Procedimento)) {
                uniqueProcedimentos.add(item.Procedimento);
                options.push({ value: item.Procedimento, label: item.Procedimento, id: item.id });
            }
        });
        
        return options;
    }, [procedimentosData]);
    


    const handleSessionChange = (option, index) => {
        const newSessionNumbers = [...sessionNumbers];
        newSessionNumbers[index] = option ? option.value : 1;
        setSessionNumbers(newSessionNumbers);
    };



    const subprocedimentoOptions = useMemo(() => {
        return procedimentosData
            .filter(item => item.Procedimento === procedimento)
            .map(item => ({ value: item.Subprocedimento, label: item.Subprocedimento, id: item.id }));
    }, [procedimentosData, procedimento]);

    const medicoOptions = [
        { value: 'Patricia', label: 'Patricia' },
        { value: 'Aline', label: 'Aline' },
        { value: 'Mariana', label: 'Mariana' },
        { value: 'Alessandra', label: 'Alessandra' },
        { value: 'Caroline', label: 'Caroline' },
        { value: 'Selma', label: 'Selma' },
        { value: 'Sigrid', label: 'Sigrid' },
    ];
    
    const solicitanteOptions = [
        { value: 'Talita', label: 'Talita' },
        { value: 'Flavia', label: 'Flavia' },
        ...medicoOptions,
    ];



    const addProcedimento = () => {
        setProcedimentos([...procedimentos, { procedimento: '', subprocedimento: '' }]);
    };
    

    const downloadData = async () => {
        if (!patientName) {
            setFormError('Por favor informar Paciente.');
            return;
        }
    
        setFormError('');  // Reset form error if patientName is filled
        setIsLoading(true);
        setErrorMessage(null);
        setIsSent(false);
        
        const procedimentosList = procedimentos.map((proc, index) => ({
            procedimento: proc.procedimento,
            subprocedimento: proc.subprocedimento,
            sessions: sessionNumbers[index] || 1,  // Add this line
            id: selectedIds[index],
        }));
    
        const payload = {
            Paciente: patientName,
            Observacao: observacao,
            Procedimentos: procedimentosList,
            Profissional: medico,
            Solicitante: solicitante,
            ids: selectedIds,
        };
        console.log(payload);
    
        try {
            const response = await fetch('https://37lhq65vzy7zibc4lnktnui7ga0ykjjn.lambda-url.us-east-1.on.aws', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(payload)
            });
    
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
    
            const data = await response.json();
            console.log(data);
    
            if (data) {  // Replace someCondition with actual check for a successful response
                setIsSent(true);
                setIsError(false);  // Reset error state
            }
    
            setProcedimentos([{ procedimento: '', subprocedimento: '' }]);  // Reset procedimentos
    
        } catch (error) {
            setErrorMessage('There was a problem with the download. Please try again.');
            setIsError(true);
            console.error('There was a problem with the fetch operation:', error);
        } finally {
            setIsLoading(false);  // Set loading state to false in the end
            resetForm();
            // Add a page refresh on the page
            window.location.reload();
        }
    };

    const removeProcedimento = (index) => {
        const newProcedimentos = procedimentos.filter((_, i) => i !== index);
        setProcedimentos(newProcedimentos);
    };

    const customStyles = {
        option: (provided, state) => ({
          ...provided,
          color: state.isSelected ? 'white' : 'black',
          backgroundColor: state.isSelected ? 'blue' : 'white',
        }),
        control: (provided) => ({
            ...provided,
            width: 300,  // Set a fixed width
            color: 'black',
            backgroundColor: 'white',
        }),
        singleValue: (provided) => ({
          ...provided,
          color: 'black',
        })
      };


    return (
            <div className="listing-pr">
                <h1>Enviar protocolo para recepção</h1>

                <div className="spacing">
                <label htmlFor="patientName">Nome Paciente:</label>
                <input 
                    type="text" 
                    id="patientName" 
                    value={patientName} 
                    onChange={(e) => setPatientName(e.target.value)} 
                    placeholder="Digite o nome do paciente" 
                />
            </div>


            <div className="spacing">
                <label htmlFor="solicitante">Solicitante:</label>
                <Select
                    id="solicitante"
                    value={solicitanteOptions.find(option => option.value === solicitante)}
                    onChange={handleSolicitanteChange}
                    options={solicitanteOptions}
                    placeholder="Selecione o Solicitante"
                    styles={customStyles}
                />
            </div>

            <div className="spacing">
                <label htmlFor="medico">Profissional Executor:</label>
                <Select
                    id="medico"
                    value={medicoOptions.find(option => option.value === medico)}
                    onChange={handleMedicoChange}
                    options={medicoOptions}
                    placeholder="Selecione o Executor"
                    styles={customStyles}
                />
            </div>



            {procedimentos.map((proc, index) => {
                const subprocedimentoOptions = procedimentosData
                    .filter(item => item.Procedimento === proc.procedimento)
                    .map(item => ({ value: item.Subprocedimento, label: item.Subprocedimento }));
  
                    
                return (
                    <div key={index} className="procedimento-container">
                        <div className="spacing flex-container">
                            <Select 
                                value={procedimentoOptions.find(option => option.value === proc.procedimento)} 
                                onChange={(option) => handleProcedimentoChange(option, index)}
                                options={procedimentoOptions} 
                                placeholder="Selecione o Procedimento" 
                                styles={customStyles}
                            />
                            {index !== 0 && (
                                <button onClick={() => removeProcedimento(index)} className="remove-button"> <AiFillDelete/> </button>
                            )}
                        </div>
                        {proc.procedimento && (
                            <div className="spacing">

                            {subprocedimentoOptions.length !== 1 ? (
                                <Select 
                                    value={subprocedimentoOptions.find(option => option.value === proc.subprocedimento)} 
                                    onChange={(option) => handleSubprocedimentoChange(option, index)} 
                                    options={subprocedimentoOptions} 
                                    placeholder="Selecione o Subprocedimento" 
                                    styles={customStyles}
                                />) : null}

                                <Select
                                    value={sessionNumbers[index] ? sessionOptions.find(option => option.value === sessionNumbers[index]) : null}
                                    onChange={(option) => handleSessionChange(option, index)}
                                    options={sessionOptions}
                                    placeholder="Número de sessões"
                                    styles={customStyles}
                                />

                            </div>
                        )}
                    </div>
                );
            })}


            <div className="spacing">
                <label htmlFor="observacao">Observação:</label>
                <textarea 
                    id="observacao" 
                    value={observacao} 
                    onChange={(e) => setObservacao(e.target.value)} 
                    placeholder="Digite a observação" 
                />
            </div>
            
            <button onClick={addProcedimento} style={{ backgroundColor: 'green' , color:"white"}}>Adicionar procedimento</button>             
            <button onClick={downloadData}>Enviar</button>
            {/* <button onClick={resetForm} style={{ marginLeft: '10px' }}>Novo Paciente</button>  New button */}

            {isSent && (
                <div style={{ color: 'green', marginTop: '10px' }}>
                    <FaCheck /> Enviado para a recepção
                </div>
            )}

            {isError && (
            <div style={{ color: 'red', marginTop: '10px' }}>
                Erro enviando o pedido
            </div>
            )}

            {formError && (
                <div style={{ color: 'red', marginTop: '10px' }}>
                    {formError}
                </div>
            )}

        </div>
    );
}

export default Procedimentos;
