// src/components/Pacientes.js
import React, { useState } from 'react';
import PatientSearchSelect from './PacientSearchSelect';
import { createPatient } from './mockDatabase';


function Pacientes() {
  const [patient, setPatient] = useState({
    Aniversario: '',
    CEP: '',
    Cidade: '',
    DtEntrada: new Date().toISOString().split('T')[0], // Default to today's date
    Endereco: '',
    Estado: '',
    Fone: '',
    Nome: '',
    Profissao: '',
    Sexo: '',
    pacientId: Math.random().toString(36).substring(2, 18) // Generate a random id of 16 characters length
  });
  const [selectedPatient, setSelectedPatient] = useState(null); // This is the patient selected from the search dropdown
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');


  const handlePatientSelect = (selectedPatient) => {
    setSelectedPatient(selectedPatient);
  }; 

  const handleInputChange = (event) => {
    setPatient({
      ...patient,
      [event.target.name]: event.target.value
    });
  };

  const handlePatientAdd = async () => {
    setLoading(true);
    try {
      await createPatient(patient);
      setMessage('Paciente criado com sucesso');
      setPatient({
        Aniversario: '',
        CEP: '',
        Cidade: '',
        DtEntrada: '',
        Endereco: '',
        Estado: '',
        Fone: '',
        Nome: '',
        Profissao: '',
        Sexo: '',
        pacientId: Math.random().toString(36).substring(2, 18) // Generate a new random id for the next patient
      });
    } catch (error) {
      setMessage('Algum erro aconteceu');
    }
    setLoading(false);
  };

  return (
    <div>
      <h2>Buscar Paciente</h2>
      <PatientSearchSelect onPatientSelect={setSelectedPatient} />
      {selectedPatient && (
        <div>
          <h2>Informações do Paciente</h2>
          <p>Nome: {selectedPatient.Nome}</p>
          <p>Aniversario: {selectedPatient.Aniversario}</p>
          <p>Estado: {selectedPatient.Estado}</p>
          <p>Cidade: {selectedPatient.Cidade}</p>
          <p>Endereço: {selectedPatient.Endereco}</p>
          <p>CEP: {selectedPatient.CEP}</p>
          <p>Data de Entrada: {selectedPatient.DtEntrada}</p>
          <p>Telefone: {selectedPatient.Fone}</p>
          <p>Profissão: {selectedPatient.Profissao}</p>
          <p>Sexo: {selectedPatient.Sexo}</p>
        </div>
      )}


      
      <h2>Adicionar novo Paciente</h2>
      <form>
        <div>
          <label>Nome:</label>
          <input name="Nome" value={patient.Nome} onChange={handleInputChange} />
        </div>
        <div>
          <label>Aniversario:</label>
          <input type="date" name="Aniversario" value={patient.Aniversario} onChange={handleInputChange} />
        </div>
        <div>
          <label>Estado:</label>
          <input name="Estado" value={patient.Estado} onChange={handleInputChange} />
        </div>
        <div>
          <label>Cidade:</label>
          <input name="Cidade" value={patient.Cidade} onChange={handleInputChange} />
        </div>
        <div>
          <label>Endereço:</label>
          <input name="Endereco" value={patient.Endereco} onChange={handleInputChange} />
        </div>
        <div>
          <label>CEP:</label>
          <input type="number" name="CEP" value={patient.CEP} onChange={handleInputChange} />
        </div>
        <div>
          <label>Data de Entrada:</label>
          <input type="date" name="DtEntrada" value={patient.DtEntrada} onChange={handleInputChange} />
        </div>
        <div>
          <label>Telefone:</label>
          <input name="Fone" value={patient.Fone} onChange={handleInputChange} />
        </div>
        <div>
          <label>Profissão:</label>
          <input name="Profissao" value={patient.Profissao} onChange={handleInputChange} />
        </div>
        <div>
          <label>Sexo:</label>
          <select name="Sexo" value={patient.Sexo} onChange={handleInputChange}>
            <option value="" disabled selected>Selecione o sexo</option>
            <option value="Masculino">Masculino</option>
            <option value="Feminino">Feminino</option>
          </select>
        </div>
        {/* Add the rest of the input fields in a similar manner */}
        <button type="button" onClick={handlePatientAdd}>Adicionar</button>
        {loading ? <p>Carregando...</p> : <p>{message}</p>}
      </form>
    </div>
  );
}

export default Pacientes;