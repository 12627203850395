import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, Link } from 'react-router-dom';

import { withAuthenticator } from '@aws-amplify/ui-react';
import { Auth, Amplify } from 'aws-amplify';
import './App.css';

import Sidebar from './components/Sidebar';
import Procedimentos from './components/Procedimentos';
import Calendar from './components/Calendar';
import Historico from './components/Historico';
import Transcription from './components/Transcription';
import Pacientes from './components/Pacientes';

// Add Amplify config
Amplify.configure({
  Auth: {
    region: 'us-east-1',
    userPoolId: 'us-east-1_vbA0WoIfI',
    userPoolWebClientId: '1mf0d541j8u21c47761cjcsv47',
    identityPoolId: null,
    oauth: {
      domain: 'patriciabandeirademelo.auth.us-east-1.amazoncognito.com',
      scope: ['email', 'profile', 'openid'],
      redirectSignIn: `${window.location.origin}`,
      redirectSignOut: `${window.location.origin}`,
      responseType: 'code'
    },
  },
});

function App() {
  const [user, setUser] = useState(null);

  useEffect(() => {
    checkUser();
  }, []);

  async function checkUser() {
    try {
      const userData = await Auth.currentAuthenticatedUser();
      setUser(userData);
    } catch (err) {
      console.log(err);
      setUser(null);
    }
  }

  async function signOut() {
    try {
      await Auth.signOut();
      setUser(null);
    } catch (error) {
      console.error('Error signing out:', error);
    }
  }

  function signIn() {
    Auth.federatedSignIn();
  }

  if (!user) {
    return <button onClick={signIn}>Sign In</button>;
  }

  return (
    <Router>
      <div className="App">
        <div className="card">
          <Sidebar />
          <div className="content">
            <Routes>
              <Route path="/" element={<Procedimentos />} />
              <Route path="/agenda" element={<div className="agenda"><Calendar /></div>} />
              <Route path="/historico" element={<div className="historico"><Historico/></div>} />
              <Route path="/transcription" element={<Transcription />} />
              <Route path="/pacientes" element={<Pacientes />} />
            </Routes>
          </div>
        </div>
      </div>
    </Router>
  );
}

export default App;