import React from 'react';
import { Link } from 'react-router-dom';
import './Sidebar.css';

function Sidebar() {
    return (
        <div className="sidebar">
            <Link to="/">Protocolos</Link>
            <Link to="/agenda">Agenda</Link>
            <Link to="/historico">Histórico</Link>
            <Link to="/transcription">Transcrição</Link>
            <Link to="/pacientes">Pacientes</Link>
        </div>
    );
}


export default Sidebar;
