import React, { useState, useEffect, useMemo, useRef } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from "@fullcalendar/interaction";
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import timelinePlugin from '@fullcalendar/timeline';
import ModalCliente from './ModalClient';
import AddEventModal from './AddEventModal';
import { Resources, addEvent, updateEvent, deleteEvent, fetchEvents, createItem} from './mockDatabase';
import formatDate  from '@fullcalendar/react';

function Calendar() {
  const [groupBy, setGroupBy] = useState('building');
  const [resources, setResources] = useState(Resources);
  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const calendarRef = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAddEventModalOpen, setIsAddEventModalOpen] = useState(false);
  const [nextEventId, setNextEventId] = useState(1);
  const [currentDate, setCurrentDate] = useState(new Date().toISOString().split('T')[0]);


useEffect(() => {
  const getEvents = async () => {
    const queryParams = {};
    if (currentDate) {
      queryParams.date = currentDate;
    }
    try {
      const fetchedData = await fetchEvents(queryParams);
      let data = [];
      if (fetchedData) {
        data = fetchedData.map(item => {
          let flattenedItem = {};
          for (let key in item) {
            flattenedItem[key] = item[key].S;
          }
          return flattenedItem;
        });
      }
      console.log('Atendimentos:', data);  // Log the fetched and flattened data
      setEvents(data);
    } catch (error) {
      console.error('Error fetching Events:', error);
    }
  };

  getEvents();
}, [currentDate]);

  const handleGroupByChange = (e) => {
    setGroupBy(e.target.value);
  };

  const handleEventClick = (info) => {
    setSelectedEvent(info.event);
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };
  

  const handleAddEventButtonClick = () => {
    setIsAddEventModalOpen(true);
  };

  const handleAddEventModalClose = () => {
    setIsAddEventModalOpen(false);
  };

  const handleEventUpdate = (updatedEvent) => {
    setEvents(events.map(event => 
      event.resourceId === updatedEvent.resourceId ? updatedEvent : event
    ));
  };

  const handleEventDelete = (eventId) => {
    console.log('Deleting event with ID:', eventId);
    console.log('Events before deletion:', events);
    setEvents(prevEvents => {
      const updatedEvents = prevEvents.filter(event => event.eventId !== eventId);
      console.log('Events after deletion:', updatedEvents);
      return updatedEvents;
    });
  };
  

  // const handleNextButtonClick = () => {
  //   const calendarApi = calendarRef.current.getApi();
  //   calendarApi.next();
  //   const currentDate = calendarApi.getDate();
  //   const formattedDate = calendarApi.formatDate(currentDate, { month: '2-digit', day: '2-digit', year: 'numeric' });
  //   console.log('Current date:', formattedDate);
  // };
  
  return (
    <div>
      <select onChange={handleGroupByChange} value={groupBy}>
        <option value="building">Procedimento</option>
        <option value="title">Profissional</option>
      </select>
      <FullCalendar
        ref={calendarRef}

        datesSet={(info) => {
          if (calendarRef.current) {
            const calendarApi = calendarRef.current.getApi();
            const currentDate = info.view.currentStart;
            const timezoneOffset = currentDate.getTimezoneOffset() * 60000; // get timezone offset in milliseconds
            const adjustedDate = new Date(currentDate.getTime() - timezoneOffset);
            const formattedDate = adjustedDate.toISOString().split('T')[0];
            setCurrentDate(formattedDate);
            console.log('Current date:', formattedDate);
          }
        }}

        schedulerLicenseKey='CC-Attribution-NonCommercial-NoDerivatives'

        plugins={[dayGridPlugin, interactionPlugin, resourceTimelinePlugin, timelinePlugin]}

        height={600}

        initialView="resourceTimelineDay"

        weekends={false}

        aspectRatio= {1.5}

        slotMinWidth={50}

        slotDuration={'00:15:00'}

        eventClick={handleEventClick}

        slotMinTime={'09:00:00'}

        slotMaxTime={'22:00:00'}

        headerToolbar={{
          left: 'today prev,next',
          center: 'title,addEvent',
          right: 'resourceTimelineDay,resourceTimelineWeek'
        }}    


        customButtons={{
          addEvent: {
            text: 'Agendar paciente',
            click: handleAddEventButtonClick,
          }
        }}


        resourceAreaColumns={[
          {
            group: groupBy === 'building',
            field: 'building',
            headerContent: 'Sala'
          },
          {
            group: groupBy === 'title',
            field: 'title',
            headerContent: 'Profissional'
          },
        ]}

        resources={resources}
        events= {events}
      />

    {isAddEventModalOpen && (
      <AddEventModal
        onClose={handleAddEventModalClose}
        onEventAdded={(newEvent) => {
          createItem(newEvent);
          setEvents([...events, newEvent]);
          // setIsAddEventModalOpen(false);
        }}
        resources={Resources}
        events={events}  // pass the events to check for double bookings
        eventToEdit={null}
      />
    )}

  {selectedEvent && (
        <ModalCliente
          event={selectedEvent}
          onClose={() => setSelectedEvent(null)}
          onUpdate={handleEventUpdate}
          onDelete={handleEventDelete}
        />
      )}


    </div>
  );
}

export default Calendar;
