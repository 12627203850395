// src/components/PatientSearchSelect.js
import React, { useState } from 'react';
import { fetchPatients } from './mockDatabase';

function PatientSearchSelect({ onPatientSelect }) {
  const [searchTerm, setSearchTerm] = useState(''); // Set initial value to empty string
  const [patients, setPatients] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedPatient, setSelectedPatient] = useState(null); // Set initial value to null

  const handlePatientSearch = async (searchTerm) => {
    setLoading(true);
    const patients = await fetchPatients(searchTerm);
    setPatients(patients);
    setLoading(false);
    console.log(patients);
  };

  return (
    <div style={{ marginBottom: '10px' }}>
      <label>Paciente:</label>
      <input
        value={searchTerm}
        onChange={e => setSearchTerm(e.target.value)}
        style={{ width: '100%' }}
      />
      <button onClick={() => handlePatientSearch(searchTerm)}>Procurar paciente</button>
      {loading ? (
        <p>Carregando...</p>
      ) : patients.length > 0 ? (
          <select
            value={selectedPatient ? selectedPatient.pacientId : ''}
            onChange={e => {
              const chosenPatient = patients.find(patient => patient.pacientId === e.target.value);
              onPatientSelect(chosenPatient);
              setSelectedPatient(chosenPatient);
              console.log(chosenPatient);
            }}
            style={{ width: '100%' }}
          >
            <option value="" disabled selected>Selecione um paciente</option>
            {patients.map((patient, index) => (
              <option value={patient.pacientId} key={index}>
                {patient.Nome}
              </option>
            ))}
          </select>
      ) : null}
    </div>
  );
}

export default PatientSearchSelect;