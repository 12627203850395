import React from 'react';
import { useEffect, useState } from 'react';
import './ModalClient.css'; // Import the CSS file
import { fetchEvent, deleteItem } from './mockDatabase';


function ModalCliente({ event, onClose, onUpdate, onDelete }) {
  // console.log("Event from inside the modal:", event);
  // const [events, setEvents] = useState([]); // Initialize it according to your need
  const [data, setData] = useState({}); // Initialize it according to your need

  useEffect(() => {
    const fetchData = async () => {
      const data = await fetchEvent({ atendimentoId: event._def.extendedProps.eventId});
      setData(data);
    };
  
    fetchData();
  }, []);
  
    const handleBackgroundClick = (e) => {
      if (e.target === e.currentTarget) {
        onClose();
      }
    };
      
    const handleDeleteClick = () => {
      if (window.confirm('Você quer mesmo deletar esse agendamento?')) {
        // Access the underlying event data object
        const eventData = event._def.extendedProps;
        console.log("Event data from inside the modal:", eventData);
        deleteItem(eventData.eventId);
        onDelete(eventData.eventId);
        onClose();
      }
    };

    

    return (
    <div 
        onClick={handleBackgroundClick}
        className='modal-background'      
      >

      {data && (
        <div className="modal-content">
          <h2>Procedimento</h2>
          <p>Paciente: {data.patient ? data.patient.S : " "}</p>
          <p>Procedimento: {data.procedure ? data.procedure.S : " "}</p>
          <p>Profissional: {data.professional ? data.professional.S : " "}</p>
          <p>Data: {data.start ? new Date(data.start.S).toLocaleString() : " "}</p>
          <button onClick={onClose}>Fechar</button>
          <button onClick={handleDeleteClick}>Deletar</button>
        </div>
      )}
        
    </div>
  );
}

export default ModalCliente;
