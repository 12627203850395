import React, { useEffect, useState } from 'react';

// Other exports remain the same

// Initialize Events as an empty array
export let Events = [];


export const Resources = [
  { id: 'a', building: 'Fotona', title: 'Patricia'},
  { id: 'n', building: 'Fotona', title: 'Carol'},
  { id: 'q', building: 'Fotona', title: 'Mariana'},
  { id: 'w', building: 'Fotona', title: 'Aline'},
  { id: 'b', building: 'Consulta', title: 'Patricia'},
  { id: 'h', building: 'Consulta', title: 'Carol'},
  { id: 'i', building: 'Consulta', title: 'Mariana'},
  { id: 'm', building: 'Consulta', title: 'Aline'},
  { id: 'l', building: 'Centro Cirurgico', title: 'Patricia'},
  { id: 'c', building: 'Centro Cirurgico', title: 'Carol'},
  { id: 'x', building: 'Centro Cirurgico', title: 'Mariana'},
  { id: 'y', building: 'Centro Cirurgico', title: 'Aline'},
  { id: 'das', building: 'Spectra', title: 'Patricia'},
  { id: 'asf', building: 'Spectra', title: 'Carol'},
  { id: 'ads', building: 'Spectra', title: 'Mariana'},
  { id: 'saf', building: 'Spectra', title: 'Aline'},
]
  
  export const Buildings = [
    { id: 'gfgsdf', name: 'Fotona' },
    { id: 'gafdhg', name: 'Consulta' },
    { id: 'rebjr', name: 'Centro Cirurgico' },
  ];
  
  export   const Professionals = [
    { id: 'at23t', name: 'Patricia' },
    { id: 'nreywre', name: 'Carol' },
    { id: 'dsaf', name: 'Aline' },
    { id: 'nasd', name: 'Mariana' },
  ];
  
  export   const Procedimentos = [
    { id: 'twretw', name: 'Laser Fotona' },
    { id: 'rewtwertwer', name: 'Consulta' },
    { id: 'retwer', name: 'Botox' },
  ];
  
  export   const Pacientes = [
    {id: 'sdgasdg', name: 'Joao'},
    {id: 'asdgasdg', name: 'Maria'},
    {id: 'sdagasdg', name: 'Jose'},
  ]
  
  export function addEvent(newEvent) {
    Events.push(newEvent);
  }
  
  export function updateEvent(updatedEvent) {
    const index = Events.findIndex(event => event.eventId === updatedEvent.eventId);
    if (index !== -1) {
      Events[index] = updatedEvent;
    }
  }
  
  export function deleteEvent(eventId) {
    const index = Events.findIndex(event => event.eventId === eventId);
    if (index !== -1) {
      Events.splice(index, 1);
    }
  }


  export const fetchPatients = async (searchTerm = '') => {
    try {
      const url = new URL('https://p3ahgfk5dzrbjxkdcxqwefkql40hsvmo.lambda-url.us-east-1.on.aws/');
      if (searchTerm) {
        url.searchParams.append('begins_with', searchTerm);
      }
      const response = await fetch(url);
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error fetching Patients:', error);
    }
  };

  export const fetchEvents = async (queryParams = {}) => {
    const url = new URL('https://knttts3aljc5dibkfhvpcxwcnu0ipuih.lambda-url.us-east-1.on.aws/');
    // url.searchParams.append('date', date);
    Object.keys(queryParams).forEach(key => url.searchParams.append(key, queryParams[key]));
  
    try {
      const response = await fetch(url);
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error fetching Events:', error);
    }
  };

  export const fetchEvent = async (queryParams) => {
    const url = new URL('https://knttts3aljc5dibkfhvpcxwcnu0ipuih.lambda-url.us-east-1.on.aws/');
    Object.keys(queryParams).forEach(key => url.searchParams.append(key, queryParams[key].S));
  
    try {
      const response = await fetch(url);
      const data = await response.json();
      return data[0];
    } catch (error) {
      console.error('Error fetching Event:', error);
    }
  };


  export const deleteItem = async (eventId) => {
    try {
      const response = await fetch('https://g7rb6ra2lb5zfzlsayhirduqzi0xdbfo.lambda-url.us-east-1.on.aws/', {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          item: eventId,
          action: 'delete',
        }),
      });
  
      if (!response.ok) {
        throw new Error('HTTP error ' + response.status);
      }
  
      const data = await response.json();
      console.log(data);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  export const createItem = async (item) => {
    try {
      const response = await fetch('https://g7rb6ra2lb5zfzlsayhirduqzi0xdbfo.lambda-url.us-east-1.on.aws/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          item: item,
          action: 'create',
        }),
      });
  
      if (!response.ok) {
        throw new Error('HTTP error ' + response.status);
      }
  
      const data = await response.json();
      console.log(data);
    } catch (error) {
      console.error('Error:', error);
    }
  };


  export const createPatient = async (patient) => {
    try {
      const response = await fetch('https://knagc35hbxgkd2ttyvbozsdeam0kmuxz.lambda-url.us-east-1.on.aws/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          item: patient,
          action: 'create',
        }),
      });
  
      if (!response.ok) {
        throw new Error('HTTP error ' + response.status);
      }
  
      const data = await response.json();
      console.log(data);
      // Pacientes.push(patient); // Add the new patient to the Pacientes array
    } catch (error) {
      console.error('Error:', error);
    }
  };