import React, { useEffect, useState, useRef } from 'react';
import { useTable, useFilters } from 'react-table';
import { Professionals, Pacientes, fetchPatients, fetchEvents } from './mockDatabase';
import PatientSearchSelect from './PacientSearchSelect';

function Historico() {
  const [events, setEvents] = useState([]);
  const [professional, setProfessional] = useState('');
  const [patient, setPatient] = useState('');
  const [patients, setPatients] = useState([]);
  const [searchType, setSearchType] = useState('patient');

  const professionalRef = useRef('');
  const patientRef = useRef('');

  useEffect(() => {
    professionalRef.current = professional;
  }, [professional]);

  useEffect(() => {
    patientRef.current = patient;
  }, [patient]);

      
      const handleSearch = () => {
        const fetchEventsData = async () => { 
          // console.log(professionalRef.current.id, patientRef.current.pacientId);
          // Only send "professional" if its not null or ""
          // Only send "patient" if its not null or ""
          const queryParams = {};
          if (patientRef.current.Nome) {
            queryParams.patient = patientRef.current.Nome;
          }
          if (professionalRef.current.id) {
            queryParams.professional = professionalRef.current.id;
          }
          console.log(queryParams)
          const events = await fetchEvents(queryParams);
          console.log(events);
          setEvents(events);
        };
      
        fetchEventsData();
      };
      
    const handlePatientSearch = async (searchTerm) => {
      const patients = await fetchPatients(searchTerm);
      setPatients(patients);
      console.log(patients);
    };

    const handlePatientSelection = (selectedPatient) => {
      setPatient(selectedPatient);
      // handleSearch();
    };
      
      // const data = React.useMemo(() => events, [events]);
      const columns = React.useMemo(
        () => [
          {
            Header: 'Título',
            accessor: data => data.title ? data.title.S : " ", // Adjusted accessor
            Filter: TextFilter,
          },
          {
            Header: 'Dia',
            accessor: data => data.start ? data.start.S.split('T')[0] : " ", // Adjusted accessor to get date before 'T'
            Filter: TextFilter,
          },
          {
            Header: 'Horário',
            accessor: data => data.start ? data.start.S.split('T')[1] : " ", // Adjusted accessor to get time after 'T'
            Filter: TextFilter,
          },
          {
            Header: 'Profissional',
            accessor: data => data.professional ? data.professional.S : " ", // Adjusted accessor
            Filter: TextFilter,
          },
          // {
          //   Header: 'Place',
          //   accessor: data => data.place ? data.place.S : " ", // Adjusted accessor
          //   Filter: TextFilter,
          // },
          {
            Header: 'Paciente',
            accessor: data => data.patient ? data.patient.S : " ", // Adjusted accessor
            Filter: TextFilter,
          },
          {
            Header: 'Procedimento',
            accessor: data => data.procedure ? data.procedure.S : " ", // Adjusted accessor
            Filter: TextFilter,
          },
          // Add more columns as needed```
        ],
        []
      );
    
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,
    } = useTable({ columns, data:events }, useFilters);
  



  return (
          <div>
            <div style={{ marginBottom: '10px' }}>
        <label>Procurar por:</label>
        <div>
          <input
            type="radio"
            value="patient"
            checked={searchType === 'patient'}
            onChange={e => setSearchType(e.target.value)}
          />
          <label>Paciente</label>
        </div>
        <div>
          <input
            type="radio"
            value="professional"
            checked={searchType === 'professional'}
            onChange={e => setSearchType(e.target.value)}
          />
          <label>Profissional</label>
        </div>
      </div>


      {searchType === 'patient' ? (
  <div style={{ marginBottom: '10px' }}>
    <PatientSearchSelect onPatientSelect={handlePatientSelection} />

  </div>
) : (

    <h1>Ainda não disponível.</h1>


  // <div style={{ marginBottom: '10px' }}>
  //   <label>Profissional:</label>
  //   <select
  //     value={professional}
  //     onChange={e => {
  //       setProfessional(e.target.value);
  //       console.log(professional); // Print the professional after setProfessional
  //     }}
  //     style={{ width: '100%' }}
  //   >
  //     <option value="" disabled hidden>Selecione um profissional</option>
  //     {Professionals.map(prof => (
  //       <option value={prof.id} key={prof.id}>{prof.name}</option>
  //     ))}
  //   </select>
  // </div>
)}

      <button onClick={handleSearch}>Procurar procedimentos</button>


    {/* Check if data is not empty before rendering the table */}
    {rows.length > 0 ? (
      <table {...getTableProps()} style={{ margin: '0 auto', width: '80%' }}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps()}>
                  {column.render('Header')}
                  <div>{column.canFilter ? column.render('Filter') : null}</div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => (
                  <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    ) : (
      <p>No data available</p>
    )}
    </div>
  );
}

// Define a default UI for filtering
function TextFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length;

  return (
    <input
      value={filterValue || ''}
      onChange={e => {
        setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
      }}
      placeholder={`Search ${count} records...`}
    />
  );
}

export default Historico;


